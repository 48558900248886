<template>
    <div>
        <Pane />
        <a-card class="container">
            <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form">
                <a-row>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="会议名称">
                            <a-input v-decorator="[
                                'name',
                                {
                                    initialValue: detail.name,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" placeholder="请输入任务名称" style="width: 200px"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="会议时间">
                            <a-date-picker v-decorator="[
                                'meetingTime',
                                {
                                    initialValue: detail.meetingTime,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="会议地点">
                            <a-input v-decorator="[
                                'place',
                                {
                                    initialValue: detail.place,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="签发对象">
                            <a-input v-decorator="[
                                'issueObject',
                                {
                                    initialValue: detail.issueObject,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>
                    <a-col :lg="12" :md="12" :sm="24">
                        <a-form-item label="决议事项">
                            <a-input v-decorator="[
                                'decisionItems',
                                {
                                    initialValue: detail.decisionItems,
                                    rules: [
                                        { required: true, message: '请输入后选择！' },
                                    ],
                                },
                            ]" style="width: 200px" />
                        </a-form-item>
                    </a-col>

                </a-row>

                <div class="center">
                    <a-space>
                        <a-button @click="$close($route.path)">关闭</a-button>
                        <a-button type="primary" :loading="loading" @click="handleSubmit"
                            >保存</a-button
                        >
                        <a-button type="primary" :loading="loading" @click="process"
                            >保存并发布</a-button
                        >
                    </a-space>
                </div>
            </a-form>
        </a-card>
    </div>
</template>
  
<script>
import request from "@/api/request";
import organization from "@/mixins/organization";
function save(data) {
    return request({
        url: "/office-service/quality/meeting/modify",
        method: "post",
        data
    });
}
function getDetail(id) {
  return request({
    url: "/office-service/quality/meeting/query/" + id,
    method: "post",
  });
}
export default {
    name: "technicalQualityScoreAdd",
    mixins: [organization],
    data() {
        return {
            form: this.$form.createForm(this),
            id: '',
            loading: false,
            detail: {}
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        this.id = id;
        getDetail(id).then(res => {
            this.detail = res;
            this.detail.date = [res.startDate, res.endDate]
        });
    },
    methods: {
        process(e) {
            e.preventDefault();
            this.hanldeSave(true);
        },
        handleSubmit(e) {
            e.preventDefault();
            this.hanldeSave(false);
        },
        hanldeSave(isRaise) {
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.loading = true;
                    save({
                        id: this.id,
                        name: values.name,
                        place: values.place,
                        issueObject: values.issueObject,
                        decisionItems: values.decisionItems,
                        meetingTime: values.meetingTime,
                        isRaise: isRaise,
                    })
                        .then(() => {
                            this.$close(this.$route.path);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    },
};
</script>
  
  
<style lang="less" scoped>
.container {
    padding: 12px;
}

.center {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>